<script lang="ts" setup>
const localePath = useLocalePath()
const { getResourceUrl, getSrcSetUrl } = useResources()
const { getStaticPageSlug } = useStaticPages()

// const infos = ['prices', 'newsletter', 'configurator']
const infos = ['prices', 'newsletter']

const tocLink = computed(() => {
  return localePath({
    name: 'slug',
    params: {
      slug: getStaticPageSlug(staticPageKeys.PRIVACY_POLICY),
    },
  })
})
</script>

<template>
  <section
    id="registration"
    class="relative mb-20 overflow-hidden pt-16 sm:overflow-visible lg:mb-60 xl:mb-96"
  >
    <img
      class="lazy loaded pointer-events-none absolute left-0 top-0 block h-full w-full object-cover"
      aria-hidden="true"
      loading="lazy"
      :srcset="
        getSrcSetUrl([
          '/assets/front/images/callToAction-bg-640.webp   640w',
          '/assets/front/images/callToAction-bg-1280.webp 1280w',
          '/assets/front/images/callToAction-bg-1920.webp 1920w',
          '/assets/front/images/callToAction-bg-2560.webp 2560w',
        ])
      "
      :src="getResourceUrl('/assets/front/images/callToAction-bg-1920.webp')"
      data-ll-status="loaded"
    />
    <div class="mx-auto !max-w-6xl px-4">
      <UiDualPaneScrollShift
        :amount="20"
        reset
        class="grid grid-cols-1 lg:grid-cols-2"
      >
        <template #left>
          <div class="relative">
            <div
              class="-my-8 bg-black px-8 py-24 text-neutral-100 sm:px-16 lg:mr-0 xl:-my-40"
              data-test="front-node-contentblock-signup-node"
            >
              <div class="mb-12">
                <h2 class="font-extended mb-4 text-4xl font-bold">
                  {{ $t('signup.info.keepInformed.title') }}
                </h2>
              </div>
              <div class="mb-8">
                <p>{{ $t('signup.info.keepInformed.description') }}</p>
              </div>

              <div v-for="info in infos" :key="info" class="mb-8">
                <h3 class="font-extended my-6 text-2xl font-bold">
                  {{ $t('signup.info.' + info + '.title') }}
                </h3>
                <p>{{ $t('signup.info.' + info + '.description') }}</p>
              </div>
            </div>
          </div>
        </template>
        <template #right="{ animationClass }">
          <div class="relative">
            <div
              class="-my-8 bg-black px-8 py-24 text-neutral-100 sm:px-16 lg:ml-0 xl:-my-40"
              :class="animationClass"
            >
              <h2 class="font-extended mb-12 text-4xl font-bold">
                {{ $t('signup.title') }}
              </h2>

              <NewsSignupForm :toc-link="tocLink" light />
            </div>
          </div>
        </template>
      </UiDualPaneScrollShift>
    </div>
  </section>
</template>

<style scoped lang="sass">
.font-extended
  font-family: Pragmatica Ext,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,Noto Sans,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol,Noto Color Emoji
</style>
